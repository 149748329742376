const URL = {
  about: "/o-nas",
  department: "/o-nas",
  people: "/o-nas",
  jobs: "/volne-pozice",
  blog: "/clanky",
  education: "/vzdelavani",
  events: "/potkej-se-s-nami",
  gdpr: "/osobni-udaje",
};

const jobFilterArray = [
  {
    heading: "Vhodné pro:",
    type: "experienceRequired",
    checkboxes: [
      {
        label: "Student",
        value: "student",
      },
      {
        label: "Absolvent",
        value: "absolvent",
      },
      {
        label: "Senior konzultant",
        value: "profesional",
      },
    ],
  },
  {
    heading: "O jaký typ úvazku máš zájem?",
    type: "jobContractType",
    checkboxes: [
      {
        label: "Zkrácený úvazek",
        value: "half",
      },
      {
        label: "Plný úvazek",
        value: "full",
      },
    ],
  },
  {
    heading: "Kde chceš pracovat?",
    type: "jobLocality",
    checkboxes: [
      {
        label: "Praha",
        value: "Praha,206696",
      },
      {
        label: "Brno",
        value: "Brno,264745",
      },
      {
        label: "Ostrava",
        value: "Ostrava,277469",
      },
    ],
  },
  {
    heading: "Na co se zaměřuješ?",
    type: "jobAim",
    checkboxes: [
      {
        label: "Ekonomie",
        value: "Ekonomie",
      },
      {
        label: "IT",
        value: "IT",
      },
      {
        label: "Matematika",
        value: "Matematika",
      },
      {
        label: "Právo",
        value: "Právo",
      },
    ],
  },
  {
    heading: "V jakém oddělení chceš pracovat?",
    type: "jobDepartment",
    checkboxes: [
      {
        label: "Business Consulting",
        value: "Business Consulting",
      },
      {
        label: "Audit",
        value: "Audit",
      },
      {
        label: "Forenzní služby",
        value: "Forenzní služby",
      },
      {
        label: "Daně",
        value: "Daně",
      },
      {
        label: "Strategie a transakce",
        value: "Strategie a transakce",
      },
      {
        label: "Technology Consulting",
        value: "Technology Consulting",
      },
      {
        label: "Interní služby",
        value: "Interní služby",
      },
      {
        label: "Právo",
        value: "Právo",
      },
    ],
  },
];

const blogFilterArray = [
  {
    heading: "Chceš číst, nebo poslouchat?",
    type: "type",
    checkboxes: [
      {
        label: "Článek",
        value: "blog",
      },
      {
        label: "Podcast",
        value: "podcast",
      },
    ],
  },
  {
    heading: "Co tě zajímá?",
    type: "category",
    checkboxes: [
      {
        label: "Začátky s EY",
        value: "beginnings",
      },
      {
        label: "Žijeme naplno",
        value: "full-living",
      },
      {
        label: "Know-How",
        value: "know-how",
      },
      {
        label: "Projekty",
        value: "projects",
      },
      {
        label: "Naši lidé",
        value: "our-people",
      },
    ],
  },
];

const careers = [
  {
    position: "Intern",
    description: "Při škole",
    text:
      "Chvíli v práci, chvíli ve škole. Přidat se k nám můžeš už jako student. Nebudeš stát stranou, zapojíme tě rovnou do projektů. Zvládat se to dá, nemusíš mít obavy. Získáš smysluplnou praxi  a uděláš si dokonalou představu, o čem je poradenství EY.",
    image: "/assets/career/1.jpg",
  },
  {
    position: "Junior konzultant",
    description: "Hned po škole",
    text:
      "Projekty, školení, projekty, školení. Od začátku se budeš hodně učit, plnit dílčí úkoly, konzultovat s týmem a chodit na schůzky s klienty. Budeš hodně pracovat, ale určitě si najdeš čas na koníčky. Poznáš spoustu kolegů, ze kterých se brzy stanou skvělí kamarádi.",
    image: "/assets/career/2.jpg",
  },
  {
    position: "Senior konzultant",
    description: "Za 2 - 3 roky",
    text:
      "Dostaneš na starost tým juniorů a přijdou nové, komplikovanější projekty. Začneš ve větším cestovat po Česku i do ciziny a stane se z tebe skutečný odborník, jehož slovo má váhu. Budeš mít větší zodpovědnost jak vůči kolegům, tak klientům.",
    image: "/assets/career/3.jpg",
  },
  {
    position: "Manager",
    description: "Za 5 - 6 let",
    text:
      "Poradenství bude tvůj životní styl. Budeš perfektně znát procesy, mít dokonalý přehled o dění v týmu a každý den nahlédneš do firem z různých odvětví. Staneš se tváří našich projektů a postupně začneš rozvíjet kontakty a přinášet do EY byznys.",
    image: "/assets/career/4.jpg",
  },
  {
    position: "Senior manager",
    description: "Po několika letech jako manažer",
    text:
      "Budeš mít komplexní práci. Od kontaktů s klienty přes vyhledávání nového byznysu až po zodpovědnost za kvalitu projektů a řízení celého týmu. Budeš předávat své zkušenosti kolegům a spolupodílet se na strategických rozhodnutích firmy.",
    image: "/assets/career/5.jpg",
  },
  {
    position: "Partner",
    description: "Možná jednou",
    text:
      "Tvoje role bude víc strategická a obchodní. Dohlédneš na fungování týmu a spokojenost klientů. Budeš hledat dlouhodobé trendy, přednášet o nich, školit kolegy, domlouvat nové projekty a řídit směrování poradenství v Česku i v rámci celého EY.",
    image: "/assets/career/6.jpg",
  },
];

const POSTS_PER_PAGE = 10;
const JOBS_PER_PAGE = 8;

module.exports = {
  URL,
  jobFilterArray,
  blogFilterArray,
  careers,
  POSTS_PER_PAGE,
  JOBS_PER_PAGE,
};
