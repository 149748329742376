import React, { useState } from 'react';
import {JOBS_PER_PAGE} from "../constants";

export const filterContext = React.createContext({});


const Provider = props => {
  const [jobsFilter, setJobsFilter] = useState({});
  const [jobsLimit, setJobsLimit] = useState(JOBS_PER_PAGE);
  const [blogFilter, setBlogFilter] = useState({});
  const [blogPagesToShow, setBlogPagesToShow] = useState([]);


  return (
    <filterContext.Provider value={{
      setJobsFilter,
      setJobsLimit,
      setBlogFilter,
      setBlogPagesToShow,
      jobsFilter,
      jobsLimit,
      blogFilter,
      blogPagesToShow
    }}>
      {props.children}
    </filterContext.Provider>
  )
};

export default ({ element }) => (
  <Provider>
    {element}
  </Provider>
);
