const Provider = require('./src/utils/filterStateProvider').default;

exports.wrapRootElement = Provider;

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location.hash) {
    setTimeout(() => {
      document.querySelector(`${location.hash}`).scrollIntoView(
        { behavior: "smooth" }
      );
    }, 0);
  }
};

exports.onRouteUpdateDelayed = () => {
  console.log("We can show loading indicator now");
};

exports.onServiceWorkerUpdateFound = () => {
  window.location.reload();
};
